import { ref } from "vue";

import { logError } from "@telia/b2x-logging";
import { addNewToast } from "@telia/b2b-message-service";

import { translateMixin } from "../locale";
import useContactPersonsData from "./contact-persons-data";

import { deletePerson } from "../services/corp-customer-datacom-messaging";
import { createModalLayout, createModalData } from "./utils/helpers";

import {
  trackCloseContactModal,
  trackOpenContactModal,
  trackRemoveContactCompleted,
} from "./utils/analytics";

const toastNr = ref(0);
const modalData = ref(createModalData());
const modalLayout = ref(createModalLayout());
const { fetchContactPersons } = useContactPersonsData();
const translate = translateMixin.methods.t;

const useRemoveContactModal = () => {
  const openContactModal = (scopeId: string, tscid: string, contactPerson: ContactPerson): void => {
    modalData.value = {
      scopeId,
      tscid,
      ...contactPerson,
    };
    modalLayout.value.showModal = true;
    trackOpenContactModal();
  };

  const closeContactModal = (): void => {
    modalLayout.value.showModal = false;
    modalLayout.value.error = false;
    modalData.value = createModalData();
    trackCloseContactModal();
  };

  const removeContact = async (): Promise<void> => {
    modalLayout.value.error = false;
    await _tryToRemoveContact();
  };

  return {
    modalData,
    modalLayout,
    openContactModal,
    closeContactModal,
    removeContact,
  };
};

export default useRemoveContactModal;

const _tryToRemoveContact = async (): Promise<void> => {
  try {
    modalLayout.value.submitting = true;

    await deletePerson(modalData.value.scopeId, modalData.value.tscid, modalData.value.personId);
    trackRemoveContactCompleted();

    await fetchContactPersons();

    modalLayout.value.showModal = false;

    addNewToast(
      `remove-contact-toast-${toastNr.value++}`,
      "success",
      translate("mybusiness.datacom_contact_person_removed_header"),
      translate("toast.notificationRemoved", {
        person: modalData.value.fullName,
      })
    );
  } catch (e) {
    logError("b2b-datacom-notification-services", "Could not remove contact");
    modalLayout.value.error = true;
  } finally {
    modalLayout.value.submitting = false;
  }
};
