<script setup lang="ts">
import { ref, computed } from "vue";

import useContactDrawer from "../../composables/contact-drawer";

import { translateMixin } from "../../locale";

const nameRegexPattern = "^[a-zA-Z0-9åÅäÄöÖ]* ?[a-zA-Z0-9åÅäÄöÖ]*$";
const nameMaxLength = 30;
const nameMinLength = 2;

const t = translateMixin.methods.t;
const { drawerLayout, submitForm, closeDrawer } = useContactDrawer();

const isSubmitting = computed(() => {
  return drawerLayout.value.submitting;
});

const nameMinlengthErrorMessage = computed(() => {
  return t("drawer.form.minLength", { minLength: nameMinLength });
});

const submit = ({ target }) => {
  const firstName = target.firstName.value;
  const lastName = target.lastName.value;
  const email = target.email.value;
  const mobilePhone = target.mobilePhone.value;
  submitForm(firstName, lastName, email, mobilePhone);
};
</script>

<template>
  <div class="form--wrapper">
    <telia-form t-id="new-contact-person-form" @submit.prevent="submit">
      <telia-text-input
        :label="t('mybusiness.first_name')"
        type="text"
        inputmode="text"
        name="firstName"
        required
        :required-error-message="t('mybusiness.serviceRequestForm.fieldRequired')"
        :minlength="nameMinLength"
        :minlength-error-message="nameMinlengthErrorMessage"
        :maxlength="nameMaxLength"
        :pattern="nameRegexPattern"
        :pattern-error-message="t('drawer.form.patternErrorMessage')"
      ></telia-text-input>
      <telia-text-input
        :label="t('mybusiness.surname')"
        type="text"
        inputmode="text"
        name="lastName"
        required
        :required-error-message="t('mybusiness.serviceRequestForm.fieldRequired')"
        :minlength="nameMinLength"
        :minlength-error-message="nameMinlengthErrorMessage"
        :maxlength="nameMaxLength"
        :pattern="nameRegexPattern"
        :pattern-error-message="t('drawer.form.patternErrorMessage')"
      ></telia-text-input>
      <telia-text-input
        :label="t('mybusiness.email')"
        type="email"
        inputmode="email"
        name="email"
        required
        :required-error-message="t('mybusiness.serviceRequestForm.fieldRequired')"
        :email-error-message="t('drawer.form.invalidEmailErrorMessage')"
      ></telia-text-input>
      <telia-text-input
        :label="t('mybusiness.mobile_phone')"
        type="tel"
        inputmode="tel"
        name="mobilePhone"
      ></telia-text-input>
      <div class="button--wrapper">
        <telia-button
          :text="t('mybusiness.cancel')"
          variant="secondary"
          type="button"
          t-id="cancel-button"
          :disabled="isSubmitting"
          @click="closeDrawer"
        ></telia-button>
        <b2x-loading-button
          :loading-label="t('drawer.form.buttons.loading')"
          :is-loading="isSubmitting"
          variant="primary"
          type="submit"
          t-id="submit-button"
          >{{ t("drawer.form.buttons.submit") }}
        </b2x-loading-button>
      </div>
    </telia-form>
  </div>
</template>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";

.form--wrapper {
  background-color: $telia-gray-50;
  padding: $telia-spacing-24;
}

div.button--wrapper {
  display: flex;
  justify-content: flex-end;
  gap: $telia-spacing-8;
}
</style>
