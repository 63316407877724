import { action, label, trackEvent as TrackEvent } from "@telia/b2b-web-analytics-wrapper";

const ANALYTICS = {
  CATEGORIES: {
    CATEGORY: "Datacom_Notification_Services",
    NEW_CONTACT_PERSON_DRAWER: "New_Contact_Person_Drawer",
  },
  LABELS: {
    NEW_CONTACT_PERSON_DRAWER: "New contact person drawer",
    SUBMIT_NEW_CONTACT_FORM: "Submit new contact form",
    SUBMIT_EDIT_CONTACT_FORM: "Submit edit contact form",
    OPEN_REMOVE_CONTACT_PERSON_MODAL: "Open remove contact person modal",
    EDIT_CONTACT_BUTTON: "Edit contact person",
    ORGANIZATION_SELECTED: "Organization selected",
  },
};

const trackEvent = (
  label: string,
  action: string,
  category: string = ANALYTICS.CATEGORIES.CATEGORY
): void => {
  TrackEvent(category, action, label);
};

export const trackOpenDrawer = () => {
  trackEvent(ANALYTICS.LABELS.NEW_CONTACT_PERSON_DRAWER, action.OPEN);
};

export const trackCloseDrawer = () => {
  trackEvent(label.CLOSED, action.CLICK, ANALYTICS.CATEGORIES.NEW_CONTACT_PERSON_DRAWER);
};

export const trackShowEditContactPersonForm = () => {
  trackEvent(
    ANALYTICS.LABELS.EDIT_CONTACT_BUTTON,
    action.CLICK,
    ANALYTICS.CATEGORIES.NEW_CONTACT_PERSON_DRAWER
  );
};

type labelKey = "SUBMIT_EDIT_CONTACT_FORM" | "SUBMIT_NEW_CONTACT_FORM";
type actionKey = "INITIATED" | "COMPLETED" | "ERROR";
export const trackContactPersonForm = (labelKey: labelKey, actionKey: actionKey) => {
  trackEvent(
    ANALYTICS.LABELS[labelKey],
    action[actionKey],
    ANALYTICS.CATEGORIES.NEW_CONTACT_PERSON_DRAWER
  );
};

export const trackOpenContactModal = () => {
  trackEvent(ANALYTICS.LABELS.OPEN_REMOVE_CONTACT_PERSON_MODAL, action.INITIATED);
};

export const trackCloseContactModal = () => {
  trackEvent(label.CANCEL, action.CLICK);
};

export const trackRemoveContactCompleted = () => {
  trackEvent(ANALYTICS.LABELS.OPEN_REMOVE_CONTACT_PERSON_MODAL, action.COMPLETED);
};

export const trackOrganizationSelected = () => {
  trackEvent(ANALYTICS.LABELS.ORGANIZATION_SELECTED, action.CLICK);
};

export const trackPaginationChange = (label: string) => {
  trackEvent(label, action.CLICK);
};
