<script setup lang="ts">
import { computed } from "vue";

import useContactDrawer from "../../composables/contact-drawer";

import { translateMixin } from "../../locale";

import Notification from "./notification.vue";
import ContactPersonForm from "./contact-person-form.vue";
import ContactPersonInfo from "./contact-person-info.vue";

const t = translateMixin.methods.t;
const { drawerLayout, closeDrawer } = useContactDrawer();

const isOpen = computed(() => {
  return drawerLayout.value.isDrawerOpen;
});

const showForm = computed(() => {
  return drawerLayout.value.showForm;
});

const renderComponent = computed(() => {
  return showForm.value ? ContactPersonForm : ContactPersonInfo;
});

const showErrorNotification = computed(() => {
  return drawerLayout.value.showErrorNotification;
});
</script>

<template>
  <b2x-drawer
    t-id="new-contact-form-drawer"
    :heading="t('mybusiness.new_contact_person')"
    position="right"
    :is-open="isOpen"
    @drawerClose="closeDrawer"
  >
    <div>
      <KeepAlive>
        <component :is="renderComponent" />
      </KeepAlive>
      <Notification v-if="showErrorNotification" />
    </div>
  </b2x-drawer>
</template>

<style lang="scss" scoped></style>
