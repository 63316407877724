<script setup lang="ts">
import { computed } from "vue";

import useContactPersonsData from "../composables/contact-persons-data";
import useContactDrawer from "../composables/contact-drawer";

import { translateMixin } from "../locale";

const t = translateMixin.methods.t;
const { contactPersonLayout, contactPersonData } = useContactPersonsData();
const { openDrawer } = useContactDrawer();

const pageLoading = computed(() => {
  return contactPersonLayout.value.page.loading;
});

const openNewContactFormDrawer = () => {
  openDrawer(
    contactPersonData.value.loggedInUser.scopeId,
    contactPersonData.value.organization.selected.tscid
  );
};
</script>

<template>
  <div>
    <template v-if="pageLoading">
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
    </template>
    <template v-else>
      <telia-button
        t-id="new-contact-person-button"
        variant="primary"
        @click="openNewContactFormDrawer"
        >{{ t("mybusiness.new_contact_person") }}
      </telia-button>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables.scss";
@import "@teliads/components/foundations/borders/variables.scss";

div {
  padding-bottom: $telia-spacing-32;
  display: flex;
  align-items: baseline;

  telia-p {
    padding-left: $telia-spacing-24;
  }

  telia-skeleton {
    height: $telia-spacing-48;
    width: 17rem;
  }
}
</style>
