<script setup lang="ts">
import { translateMixin } from "../locale";

const t = translateMixin.methods.t;
</script>

<template>
  <div class="telia-notification">
    <telia-notification status="error" heading-tag="h1">
      <span slot="heading">{{ t("errorNotification.header") }}</span>
      <telia-text-spacing slot="content">
        <telia-p
          >{{ t("errorNotification.content") }}
          <b2x-sspa-link href="" :disable-visited="true">{{
            t("errorNotification.link")
          }}</b2x-sspa-link></telia-p
        >
      </telia-text-spacing>
    </telia-notification>
  </div>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables.scss";
@import "@teliads/components/foundations/breakpoints/variables.scss";

.telia-notification {
  width: 100%;
  margin-top: $telia-spacing-32;

  @media screen and (min-width: $telia-fluid-breakpoint-medium) {
    width: 60%;
  }
}
</style>
