import { ref } from "vue";

import { logError } from "@telia/b2x-logging";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { hasPermission, getOrganizations } from "@telia/b2b-logged-in-service";

import { translateMixin } from "../locale";

import { createTableHeaders } from "./utils/helpers";
import { getContactPersons, deletePerson } from "../services/corp-customer-datacom-messaging";
import { createContactPersonData, createContactPersonLayout } from "./utils/helpers";

import { trackOrganizationSelected, trackPaginationChange } from "./utils/analytics";

const contactPersonData = ref(createContactPersonData());
const contactPersonLayout = ref(createContactPersonLayout());
const translate = translateMixin.methods.t;

const useContactPersonsData = () => {
  const initialize = () => {
    _initializeData();
    setupTableHeaders();
  };

  const paginationChange = (detail: { page: number; pageSize: number }): void => {
    _tablePaginationChangeGoogleAnalytics(detail);
    _changePage(detail.page, detail.pageSize);
  };

  const setSelectedOrganization = async (tscid: string): Promise<void> => {
    contactPersonData.value.organization.selected = contactPersonData.value.organization.list.find(
      (org: Organization) => tscid === org.tscid
    ) ?? { tscid: "", name: "", organizationNumber: "" };

    if (contactPersonData.value.organization.selected.tscid !== "") {
      trackOrganizationSelected();
      await _tryToFetchContactPersons();
    } else {
      contactPersonData.value.contactPersons = [];
      _generateTabledata();
    }
  };

  const toggleIsWideTable = (): void => {
    contactPersonLayout.value.isWideTable = !contactPersonLayout.value.isWideTable;
  };

  const toggleTableHeaderChecked = (columnName: string): void => {
    const tableHeaderIndex = _getTableHeaderIndex(columnName);

    contactPersonData.value.tableHeaders[tableHeaderIndex].isChecked =
      !contactPersonData.value.tableHeaders[tableHeaderIndex].isChecked;
  };

  const resetCheckedTableHeaders = (): void => {
    contactPersonData.value.tableHeaders.map((header) => {
      header.isChecked = true;
    });
  };

  const fetchContactPersons = async () => {
    await _tryToFetchContactPersons();
  };

  const getContactPerson = (rowNumber: number) => {
    return contactPersonData.value.contactPersons[rowNumber + _getOffset()];
  };

  return {
    contactPersonData,
    contactPersonLayout,
    initialize,
    fetchContactPersons,
    removeContact: deletePerson,
    paginationChange,
    setSelectedOrganization,
    toggleIsWideTable,
    toggleTableHeaderChecked,
    resetCheckedTableHeaders,
    getContactPerson,
  };
};

export default useContactPersonsData;

const _initializeData = async (): Promise<void> => {
  await _checkLoggedInUserPermissions();
  if (contactPersonLayout.value.page.showAccessError) return;
  await _tryToSetLoggedInUserScopeID();
  await _tryToSetOrganizationList();
  contactPersonLayout.value.page.loading = false;
};

const setupTableHeaders = (): void => {
  contactPersonData.value.tableHeaders = createTableHeaders(translate);
};

const _getTableHeaderIndex = (columnName: string): number => {
  return contactPersonData.value.tableHeaders.findIndex(
    (tableHeader: TableHeader) => tableHeader.title === columnName
  );
};

const _generateTabledata = (): void => {
  const offset = _getOffset();
  contactPersonData.value.tabledata = contactPersonData.value.contactPersons
    .filter(
      (_item, index) =>
        offset <= index && index < contactPersonData.value.pagination.pagesize + offset
    )
    .map((service, index) => [
      _createContactPersonTableLink(service.fullName, service.personId),
      service.mobilePhone + "",
      service.email,
      service.organizationNumber,
      service.nrOfNotifications + "",
      index,
    ]);
};

const _getOffset = (): number => {
  return (
    contactPersonData.value.pagination.pagesize * contactPersonData.value.pagination.page -
    contactPersonData.value.pagination.pagesize
  );
};

const _changePage = (pageNumber: number, pageSize: number): void => {
  contactPersonData.value.pagination.page = pageNumber;
  contactPersonData.value.pagination.pagesize = pageSize;

  _generateTabledata();
};

const _createContactPersonTableLink = (name: string, personId: number): TableContactPersonLink => {
  return {
    href: `/foretag/mybusiness/${contactPersonData.value.loggedInUser.scopeId}/hantera/produkter-tjanster/datanet-vpn/notifieringar/${personId}`,
    content: name,
    "disable-visited": true,
  };
};

const _tryToSetLoggedInUserScopeID = async (): Promise<void> => {
  try {
    contactPersonData.value.loggedInUser.scopeId = await getScopeIdOrThrow();
  } catch {
    contactPersonLayout.value.page.error = true;
    _logError("Failed to set scopeId");
  }
};

const _checkLoggedInUserPermissions = async (): Promise<void> => {
  const NOTIFICATIONS_READ_ACCESS = "READ_DATACOM_NOTIFICATIONS";
  const NOTIFICATIONS_WRITE_ACCESS = "WRITE_DATACOM_NOTIFICATIONS";
  try {
    contactPersonLayout.value.page.showAccessError = !(
      (await hasPermission(NOTIFICATIONS_READ_ACCESS)) &&
      (await hasPermission(NOTIFICATIONS_WRITE_ACCESS))
    );
  } catch {
    contactPersonLayout.value.page.error = true;
    _logError("Failed to fetch logged in users accesses");
  }
};

const _tryToSetOrganizationList = async (): Promise<void> => {
  try {
    const orgs = await getOrganizations();

    contactPersonData.value.organization.list = orgs
      .map((org) => {
        return {
          name: org.name ?? "",
          tscid: org.tscid ?? "",
          organizationNumber: org.organizationNumber ?? "",
        };
      })
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    contactPersonData.value.organization.selected = _setDefaultSelectedOrganization();
    await _tryToFetchContactPersons();
  } catch {
    contactPersonLayout.value.page.error = true;
    _logError("Failed to fetch organizations info");
  }
};

const _setDefaultSelectedOrganization = (): Organization => {
  const tscid = window.localStorage.getItem(
    `selectedOrganisation-${contactPersonData.value.loggedInUser.scopeId}`
  );

  return (
    contactPersonData.value.organization.list.find((org) => org.tscid === tscid) ??
    contactPersonData.value.organization.list[0]
  );
};

const _tryToFetchContactPersons = async (): Promise<void> => {
  try {
    contactPersonLayout.value.fetchingContactPersons.loading = true;
    contactPersonLayout.value.fetchingContactPersons.error = false;

    contactPersonData.value.contactPersons = await getContactPersons(
      contactPersonData.value.loggedInUser.scopeId,
      contactPersonData.value.organization.selected.tscid
    );

    if (_shouldMoveUpAPage()) {
      contactPersonData.value.pagination.page -= 1;
    }

    _generateTabledata();
  } catch {
    contactPersonLayout.value.fetchingContactPersons.error = true;
  } finally {
    contactPersonLayout.value.page.loading = false;
    contactPersonLayout.value.fetchingContactPersons.loading = false;
  }
};

const _shouldMoveUpAPage = (): boolean => {
  return (
    contactPersonData.value.pagination.page > 1 &&
    contactPersonData.value.contactPersons.length % 10 === 0
  );
};

const _logError = (message: string): void => {
  logError("b2b-datacom-notification-services", message);
};

const _tablePaginationChangeGoogleAnalytics = (detail: {
  page: number;
  pageSize: number;
}): void => {
  const label = _pageSizeHasChanged(detail.pageSize)
    ? `Page size selected ${detail.pageSize}`
    : `Page selected ${detail.page}`;
  trackPaginationChange(label);
};

const _pageSizeHasChanged = (newpageSize: number): boolean => {
  return newpageSize !== contactPersonData.value.pagination.pagesize;
};
