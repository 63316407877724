<script setup lang="ts">
import { computed, ref, onBeforeMount } from "vue";

import useContactPersonsData from "./composables/contact-persons-data";

import { translateMixin, translateSetup } from "./locale";

import ServicesTable from "./components/services-table.vue";
import NewContactPerson from "./components/new-contact-person.vue";
import RemoveContactModal from "./components/remove-contact-modal.vue";
import NoAccessNotification from "./components/no-access-notification.vue";
import PageLoadErrorNotification from "./components/page-load-error-notification.vue";
import NewContactFormDrawer from "./components/new-contact-form-drawer/new-contact-form-drawer.vue";

const reRenderKey = ref(0);
const t = translateMixin.methods.t;
const { contactPersonLayout, initialize, setSelectedOrganization } = useContactPersonsData();

onBeforeMount(async () => {
  await translateSetup(["mybusiness"]);
  reRenderKey.value++;
  initialize();
});

const showAccessError = computed(() => {
  return contactPersonLayout.value.page.showAccessError;
});

const pageError = computed(() => {
  return contactPersonLayout.value.page.error;
});

const isWideTable = computed(() => {
  return contactPersonLayout.value.isWideTable;
});

const changeOrganisation = (event: CustomEvent) => {
  setSelectedOrganization(event.detail);
};
</script>

<template>
  <b2b-layout
    :heading="t('mybusiness.datacom_header')"
    :page-title="t('mybusiness.datacom_header')"
    show-organisation-selector="true"
    @organisationSelected="changeOrganisation"
  >
    <div slot="header">
      <telia-grid>
        <telia-text-spacing class="header-slot-content">
          <telia-p disable-hyphenation="true">
            {{ t("mybusiness.datacom_description") }}
          </telia-p>
          <new-contact-person />
        </telia-text-spacing>
      </telia-grid>
    </div>

    <div
      t-id="b2b-datacom-notification-services-page"
      class="notification-services-wrapper"
      :key="reRenderKey"
    >
      <template v-if="pageError">
        <telia-grid>
          <page-load-error-notification />
        </telia-grid>
      </template>

      <template v-else-if="showAccessError">
        <telia-grid>
          <no-access-notification />
        </telia-grid>
      </template>

      <template v-else>
        <div
          t-id="telia-grid-table-wrapper"
          class="telia-grid-table-wrapper"
          :class="{ 'wide-table': isWideTable }"
        >
          <telia-grid>
            <telia-row>
              <telia-col width="12">
                <services-table />
              </telia-col>
            </telia-row>
          </telia-grid>
        </div>
      </template>
      <new-contact-form-drawer />
      <remove-contact-modal />
    </div>
  </b2b-layout>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/breakpoints/variables.scss";

.telia-grid-table-wrapper telia-grid {
  transition: max-width 0.3s ease-in;
}

.telia-grid-table-wrapper.wide-table telia-grid {
  max-width: 100%;
}

.header-slot-content {
  display: flex;
  flex-direction: column;

  telia-p {
    max-width: 80%;
  }

  @media screen and (min-width: $telia-fluid-breakpoint-medium) {
    justify-content: space-between;
    flex-direction: row;

    telia-p {
      max-width: 50%;
    }
  }
}
</style>
