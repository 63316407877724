import { corpCustomerDatacomMessaging } from "@telia/b2b-rest-client";
import {
  ContactPersonResponseDTO,
  CreateAndUpdatePersonResponseDTO,
} from "@telia/b2b-rest-client/dist/corp-customer-datacom-messaging";

export const getContactPersons = (scopeId: string, tscid: string): Promise<ContactPerson[]> => {
  return new Promise<ContactPerson[]>((resolve, reject) => {
    corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.listPersons(scopeId, tscid)
      .then((data: ContactPersonResponseDTO[]) => resolve(mapContactPersons(data)))
      .catch((error) => reject(error));
  });
};

export const createPerson = (
  scopeId: string,
  tscid: string,
  payload: corpCustomerDatacomMessaging.CreateAndUpdatePersonRequestDTO
): Promise<ContactPersonInfo> => {
  return new Promise<ContactPersonInfo>((resolve, reject) => {
    corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.createPerson(
      scopeId,
      tscid,
      payload
    )
      .then((data) => {
        resolve(mapCreatedContactPerson(data, payload));
      })
      .catch((error) => {
        reject(error.status);
      });
  });
};

export const updatePerson = (
  scopeId: string,
  tscid: string,
  personId: number,
  payload: corpCustomerDatacomMessaging.CreateAndUpdatePersonRequestDTO
): Promise<ContactPersonInfo> => {
  return new Promise<ContactPersonInfo>((resolve, reject) => {
    corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.updatePerson(
      scopeId,
      tscid,
      personId,
      payload
    )
      .then((data) => {
        resolve(mapCreatedContactPerson(data, payload));
      })
      .catch((error) => {
        reject(error.status);
      });
  });
};

export const deletePerson = (scopeId: string, tscid: string, personId: number): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.deletePerson(
      scopeId,
      tscid,
      personId
    )
      .then((data: string) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const mapContactPersons = (data: ContactPersonResponseDTO[]): ContactPerson[] => {
  return data.map((contactPerson) => {
    return {
      email: contactPerson.email || "",
      personId: contactPerson.personId || 0,
      nrOfNotifications: contactPerson.nrOfNotifications || 0,
      fullName: contactPerson.fullName || "",
      mobilePhone: contactPerson.mobilePhone || "",
      organizationNumber: contactPerson.organisationNumber || "",
    };
  });
};

const mapCreatedContactPerson = (
  data: CreateAndUpdatePersonResponseDTO,
  payload: corpCustomerDatacomMessaging.CreateAndUpdatePersonRequestDTO
): ContactPersonInfo => {
  return {
    email: payload.email || "",
    mobileNumber: payload.mobilePhone || "",
    fullName: `${payload.firstName} ${payload.lastName}` || "",
    personId: data.personId || 0,
  };
};
