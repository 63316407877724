<script setup lang="ts">
import { computed } from "vue";

import useContactPersonsData from "../composables/contact-persons-data";

import { translateMixin } from "../locale";
import useRemoveContactModal from "../composables/remove-contact-modal";

const pagesizes = [10, 25, 50];
const t = translateMixin.methods.t;
const {
  contactPersonData,
  contactPersonLayout,
  paginationChange,
  toggleIsWideTable,
  toggleTableHeaderChecked,
  resetCheckedTableHeaders,
  getContactPerson,
} = useContactPersonsData();
const { openContactModal } = useRemoveContactModal();

const tableHeaders = computed(() => {
  return contactPersonData.value.tableHeaders;
});

const rowData = computed(() => {
  const REMOVE_BUTTON_INDEX = 5;
  return contactPersonData.value.tabledata.map((rowData) => {
    return rowData.map((column, index) => {
      if (index === REMOVE_BUTTON_INDEX) {
        return {
          attrs: { text: t("mybusiness.remove"), type: "button" },
          rowNumber: column,
        };
      }
      return column;
    });
  });
});

const error = computed(() => {
  return contactPersonLayout.value.fetchingContactPersons.error;
});

const message = computed(() => {
  if (error.value) {
    return t("mybusiness.datacom_failed_to_fetch_service_list");
  }

  if (showOrganisationIsNotSelectedMessage.value) {
    return t("mybusiness.datacom_organization_not_selected_in_table");
  }

  if (listLength.value === 0) {
    return t("mybusiness.datacom_fetch_service_list_empty");
  }

  return "";
});

const pageIsLoading = computed(() => {
  return contactPersonLayout.value.page.loading;
});

const listLength = computed(() => {
  return contactPersonData.value.contactPersons.length;
});

const selectedOrganizationIsEmpty = computed(() => {
  return contactPersonData.value.organization.selected.tscid === "";
});

const pagesize = computed(() => {
  return contactPersonData.value.pagination.pagesize;
});

const pageNumber = computed(() => {
  return contactPersonData.value.pagination.page;
});

const showPaginator = computed(() => {
  return listLength.value > pagesizes[0];
});

const showOrganisationIsNotSelectedMessage = computed(() => {
  return (
    listLength.value === 0 &&
    selectedOrganizationIsEmpty.value &&
    !showTableLoadingSpinnerMask.value
  );
});

const showTableLoadingSpinnerMask = computed(() => {
  return contactPersonLayout.value.fetchingContactPersons.loading && !pageIsLoading.value;
});

const isWideTable = computed(() => {
  return contactPersonLayout.value.isWideTable;
});

const removeClicked = (event: CustomEvent) => {
  const removeContactPerson = getContactPerson(event.detail.rowNumber);
  openContactModal(
    contactPersonData.value.loggedInUser.scopeId,
    contactPersonData.value.organization.selected.tscid,
    removeContactPerson
  );
};

const checkBoxSelected = (event: CustomEvent) => {
  toggleTableHeaderChecked(event.detail);
};

const handlePaginationChange = (event: CustomEvent) => {
  paginationChange(event.detail);
};
</script>

<template>
  <section t-id="services-table-wrapper" class="services-table-wrapper">
    <div
      v-if="showTableLoadingSpinnerMask"
      class="contact-person-table__mask"
      t-id="list-loading-indicator"
    >
      <b2x-spinner size="large"></b2x-spinner>
    </div>
    <b2x-controlled-table
      t-id="services-table"
      :is-loading="pageIsLoading"
      :columns="JSON.stringify(tableHeaders)"
      :data="JSON.stringify(rowData)"
      :message="message"
      show-expand-button="true"
      :is-wide-table="isWideTable"
      :column-filter-label="t('mybusiness.columnManager')"
      :reset-column-label="t('mybusiness.resetColumns')"
      @buttonClicked="removeClicked"
      @controlledTableExpandClicked="toggleIsWideTable"
      @checkBoxSelect="checkBoxSelected"
      @resetButtonClick="resetCheckedTableHeaders"
    />
    <b2x-paginator
      v-if="showPaginator"
      :list-length="listLength"
      :page-sizes="JSON.stringify(pagesizes)"
      :default-page-size="pagesize"
      :page-number="pageNumber"
      @paginationChange="handlePaginationChange"
    />
  </section>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/variables.scss";

.services-table-wrapper {
  position: relative;

  .contact-person-table__mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: $telia-transparent-white-900;
  }
}
</style>
