import { ref } from "vue";

import { logError } from "@telia/b2x-logging";

import useContactPersonsData from "./contact-persons-data";

import { createDrawerLayout, createDrawerData } from "./utils/helpers";
import { createPerson, updatePerson } from "../services/corp-customer-datacom-messaging";

import {
  trackCloseDrawer,
  trackOpenDrawer,
  trackShowEditContactPersonForm,
  trackContactPersonForm,
} from "./utils/analytics";

const drawerLayout = ref(createDrawerLayout());
const drawerData = ref(createDrawerData());
const { fetchContactPersons } = useContactPersonsData();

const useContactDrawer = () => {
  const openDrawer = (scopeId: string, tscid: string): void => {
    drawerData.value.scopeId = scopeId;
    drawerData.value.tscid = tscid;
    drawerLayout.value.isDrawerOpen = true;
    trackOpenDrawer();
  };

  const closeDrawer = (): void => {
    drawerLayout.value = createDrawerLayout();
    drawerData.value = createDrawerData();
    trackCloseDrawer();
  };

  const submitForm = async (
    firstName: string,
    lastName: string,
    email: string,
    mobilePhone: string
  ): Promise<void> => {
    drawerLayout.value.editContactInfo
      ? await _tryToUpdatePerson(firstName, lastName, email, mobilePhone)
      : await _tryToCreatePerson(firstName, lastName, email, mobilePhone);
  };

  const closeDrawerNotification = (): void => {
    drawerLayout.value.showErrorNotification = false;
  };

  const showEditContactPersonForm = (): void => {
    drawerLayout.value.showForm = true;
    drawerLayout.value.editContactInfo = true;
    trackShowEditContactPersonForm();
  };

  return {
    drawerLayout,
    drawerData,
    openDrawer,
    closeDrawer,
    submitForm,
    showEditContactPersonForm,
    closeDrawerNotification,
  };
};

export default useContactDrawer;

const _tryToUpdatePerson = async (
  firstName: string,
  lastName: string,
  email: string,
  mobilePhone: string
): Promise<void> => {
  try {
    drawerLayout.value.submitting = true;
    drawerLayout.value.showErrorNotification = false;
    drawerLayout.value.contactPersonExistsError = false;

    trackContactPersonForm("SUBMIT_EDIT_CONTACT_FORM", "INITIATED");

    const payload = {
      firstName,
      lastName,
      email,
      mobilePhone,
    };

    drawerData.value.contactPersonInfo = await updatePerson(
      drawerData.value.scopeId,
      drawerData.value.tscid,
      drawerData.value.contactPersonInfo.personId,
      payload
    );
    drawerLayout.value.showForm = false;

    trackContactPersonForm("SUBMIT_EDIT_CONTACT_FORM", "COMPLETED");
    await fetchContactPersons();
  } catch (errorStatus) {
    trackContactPersonForm("SUBMIT_EDIT_CONTACT_FORM", "ERROR");

    drawerLayout.value.contactPersonExistsError = (errorStatus as number) === 409;
    drawerLayout.value.contactPersonExistsError
      ? _logError("Contact person already exists")
      : _logError("Could not submit form");
    drawerLayout.value.showErrorNotification = true;
  } finally {
    drawerLayout.value.submitting = false;
  }
};

const _tryToCreatePerson = async (
  firstName: string,
  lastName: string,
  email: string,
  mobilePhone: string
): Promise<void> => {
  try {
    drawerLayout.value.submitting = true;
    drawerLayout.value.showErrorNotification = false;
    drawerLayout.value.contactPersonExistsError = false;

    trackContactPersonForm("SUBMIT_NEW_CONTACT_FORM", "INITIATED");

    const payload = {
      firstName,
      lastName,
      email,
      mobilePhone,
    };

    drawerData.value.contactPersonInfo = await createPerson(
      drawerData.value.scopeId,
      drawerData.value.tscid,
      payload
    );
    drawerLayout.value.showForm = false;

    trackContactPersonForm("SUBMIT_NEW_CONTACT_FORM", "COMPLETED");
    await fetchContactPersons();
  } catch (errorStatus) {
    trackContactPersonForm("SUBMIT_NEW_CONTACT_FORM", "ERROR");
    drawerLayout.value.contactPersonExistsError = (errorStatus as number) === 409;
    drawerLayout.value.contactPersonExistsError
      ? _logError("Contact person already exists")
      : _logError("Could not submit form");
    drawerLayout.value.showErrorNotification = true;
  } finally {
    drawerLayout.value.submitting = false;
  }
};

const _logError = (message: string): void => {
  logError("b2b-datacom-notification-services", message);
};
