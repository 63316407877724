<script setup lang="ts">
import { computed } from "vue";

import useContactDrawer from "../../composables/contact-drawer";

import { translateMixin } from "../../locale";

const t = translateMixin.methods.t;
const { drawerData, showEditContactPersonForm } = useContactDrawer();

const contactInfo = computed(() => {
  return drawerData.value.contactPersonInfo;
});

const getNewNotificationServiceHref = computed(() => {
  const scopeId = drawerData.value.scopeId;
  const personId = contactInfo.value.personId;
  return `/foretag/mybusiness/${scopeId}/hantera/produkter-tjanster/datanet-vpn/notifieringar/${personId}/ny-overvakning`;
});
</script>

<template>
  <div>
    <telia-button
      :text="t('mybusiness.datacom_contact_details_notices')"
      variant="text"
      type="button"
      :right-icon="JSON.stringify({ name: 'edit', size: 'sm' })"
      t-id="edit-contact-info-button"
      @click="showEditContactPersonForm"
      class="edit-contact-info-button"
    ></telia-button>
    <div class="contact-person-info--container">
      <telia-p t-id="user-name">{{ contactInfo.fullName }}</telia-p>
      <telia-p t-id="user-email">{{ contactInfo.email }}</telia-p>
      <telia-p t-id="user-mobile-number">{{ contactInfo.mobileNumber }}</telia-p>
    </div>
    <b2x-sspa-link variant="standalone" :href="getNewNotificationServiceHref">
      <div class="notification-service-link">
        <telia-icon size="sm" name="plus"></telia-icon>
        <span>{{ t("mybusiness.datacom_new_notification_service") }}</span>
      </div>
    </b2x-sspa-link>
  </div>
</template>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";

.contact-person-info--container {
  margin-bottom: $telia-spacing-20;
}

.edit-contact-info-button :deep(.telia-icon__container) {
  color: $telia-black;
}

.notification-service-link {
  display: flex;
  align-items: center;
}
</style>
