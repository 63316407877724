<script setup lang="ts">
import { computed } from "vue";

import useRemoveContactModal from "../composables/remove-contact-modal";

import { translateMixin } from "../locale";

const t = translateMixin.methods.t;
const { modalData, modalLayout, closeContactModal, removeContact } = useRemoveContactModal();

const showRemoveContactModal = computed(() => {
  return modalLayout.value.showModal;
});

const header = computed(() => {
  return !hasError.value
    ? `${t("mybusiness.remove")} ${modalData.value.fullName}`
    : t("removeContact.error.header");
});

const content = computed(() => {
  const nrOfNotifications = modalData.value.nrOfNotifications;
  return !hasError.value
    ? t("removeContact.message", { nrOfNotifications })
    : t("removeContact.error.message");
});

const hasError = computed(() => {
  return modalLayout.value.error;
});

const isSubmitting = computed(() => {
  return modalLayout.value.submitting;
});
</script>

<template>
  <b2x-modal
    t-id="remove-contact-modal"
    :is-open="showRemoveContactModal"
    @closeModal="closeContactModal"
    :show-close-button="false"
  >
    <div slot="modal-body">
      <telia-heading variant="title-300" tag="h3" t-id="remove-contact-modal-heading"
        >{{ header }}
      </telia-heading>
      <telia-p t-id="remove-contact-modal-message">{{ content }}</telia-p>
    </div>
    <div slot="button-footer" class="button-footer">
      <template v-if="hasError">
        <telia-button
          variant="primary"
          @click="closeContactModal"
          t-id="remove-contact-modal-agree-button"
          type="button"
          >{{ t("mybusiness.GOT_IT") }}
        </telia-button>
      </template>
      <template v-if="!hasError">
        <telia-button
          variant="tertiary-purple"
          @click="closeContactModal"
          t-id="remove-contact-modal-abort-button"
          type="button"
          >{{ t("mybusiness.cancel") }}
        </telia-button>
        <b2x-loading-button
          t-id="remove-contact-modal-remove-button"
          variant="primary"
          type="button"
          :loading-label="t('removeContact.buttons.loading')"
          :is-loading="isSubmitting"
          @click="removeContact"
          >{{ t("removeContact.buttons.submit") }}
        </b2x-loading-button>
      </template>
    </div>
  </b2x-modal>
</template>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/variables.scss";
@import "@teliads/components/foundations/typography/variables.scss";

div.modal-body {
  padding: $telia-spacing-24;

  telia-heading {
    padding-bottom: $telia-spacing-12;
  }
}

div.button-footer {
  display: flex;
  justify-content: flex-end;
  padding: $telia-spacing-24;
}
</style>
