import { Translation } from "@telia/b2b-i18n/types/types";

export const createContactPersonData = (): ContactPersonsData => ({
  contactPersons: [],
  loggedInUser: { scopeId: "" },
  organization: {
    selected: { tscid: "", organizationNumber: "", name: "" },
    list: [],
  },
  tabledata: [],
  tableHeaders: [],
  pagination: {
    pagesize: 10,
    page: 1,
  },
});

export const createContactPersonLayout = (): ContactPersonsLayout => ({
  page: {
    error: false,
    loading: true,
    showAccessError: false,
  },
  fetchingContactPersons: {
    error: false,
    loading: false,
  },
  isWideTable: false,
});

export const createDrawerLayout = (): DrawerLayout => ({
  showForm: true,
  isDrawerOpen: false,
  submitting: false,
  showErrorNotification: false,
  editContactInfo: false,
  contactPersonExistsError: false,
});

export const createDrawerData = (): DrawerData => ({
  scopeId: "",
  tscid: "",
  contactPersonInfo: {
    fullName: "",
    email: "",
    mobileNumber: "",
    personId: 0,
  },
});

export const createModalLayout = (): ModalLayout => ({
  showModal: false,
  text: "",
  error: false,
  submitting: false,
});

export const createModalData = (): ModalData => ({
  scopeId: "",
  tscid: "",
  mobilePhone: "",
  email: "",
  personId: 0,
  nrOfNotifications: 0,
  organizationNumber: "0",
  fullName: "",
});

export const createTableHeaders = (
  translate: (
    key: string,
    data?: Record<string, string | number | Translation> | undefined
  ) => string
): TableHeader[] => [
  {
    title: translate("mybusiness.form.label.name"),
    type: "sspa-link",
    isChecked: true,
    disabled: true,
  },
  {
    title: translate("mybusiness.phone"),
    type: "text",
    isChecked: true,
  },
  {
    title: translate("mybusiness.email"),
    type: "text",
    isChecked: true,
  },
  {
    title: translate("mybusiness.invoice.organisation.number"),
    type: "text",
    isChecked: true,
  },
  {
    title: translate("mybusiness.total_notification_services"),
    type: "text",
    isChecked: true,
  },
  {
    title: translate("mybusiness.remove_contact_person"),
    type: "button",
    isChecked: true,
  },
];
